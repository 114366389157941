import React from "react"
import { Link } from "gatsby"
import Layout from "./../../components/layouts/Default"
import Seo from "./../../components/layouts/Seo"
import Header from "./../../components/common/Header"
import SvgFeature from "./../../components/common/SvgFeature"
import { graphql, useStaticQuery } from "gatsby"

import FeatureSection from "./../../components/containers/FeatureSection"
import ContContentTwoColumns from "./../../components/containers/ContContentTwoColumns"
import ContentTextbox from "./../../components/common/ContentTextbox"

import CtaSection from "./../../components/common/CtaSection"

import illustrationsHomeBuy from "./../../data/img/illustrations/home-buy.svg"

export default function Page() {
  const data = useStaticQuery(graphql`
    query FinanzierungenBaufinanzierung {
      image: file(
        relativePath: { eq: "headers/dierda_baufinanzierung_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const anfrageLink = "/finanzierungen/baufinanzierung/anfragen/"

  const svg = {
    finance: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="7" y="9" width="14" height="10" rx="2" />
        <circle cx="14" cy="14" r="2" />
        <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
      </svg>
    ),
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    ),
  }

  const content = {
    warum: (
      <ContentTextbox title="Warum lohnt sich ein Vergleich von Immobilienfinanzierungen?">
        <p>
          Bei einem Bau oder Kauf Deiner Immobilie musst Du meist Kreditbeträge
          von mehreren hunderttausend Euro aufnehmen.{" "}
          <strong>
            Aufgrund des hohen Beitrags machen kleine Unterschiede in den
            Zinssätzen große Unterschiede bei der Rückzahlung.
          </strong>{" "}
          Damit Du viel Geld sparen kannst, vergleichen wir für Dich bei fast
          allen deutschen Banken und finden Dir den passenden Kredit. Natürlich
          stellen wir im Vorfeld mit Dir alle nötigen Dokumente zusammen, um
          dich so bestmöglich bei den Banken zu positionieren.{" "}
        </p>
      </ContentTextbox>
    ),
    wann: (
      <ContentTextbox title="Wann ist der richtige Zeitpunkt für meine Immobilienfinanzierung?">
        <p>
          Um sich das Geld für deine Wunschimmobilie zu einem niedrigen Zinssatz
          zu sichern, ist neben dem Vergleichen auch der Zeitpunkt entscheidend.
          Glücklicherweise befinden wir uns aktuell in einer Niedrigzinsphase.
          Dadurch sind die Zinssätze für Immobilienfinanzierungen grundsätzlich
          sehr niedrig.{" "}
          <strong> Jetzt ist der günstigste Zeitpunkt um zu handeln. </strong>
        </p>
      </ContentTextbox>
    ),
    was: (
      <ContentTextbox title="Was solltest Du bei (D)einer Immobilienfinanzierung beachten?">
        <h3>1. Finanz-Check der persönlichen und finanziellen Situation</h3>
        <p>
          Bevor Du voreilig eine Immobilie erwirbst, solltest Du Dich fragen ob
          diese Deinen persönlichen Bedürfnissen entspricht. Wie wahrscheinlich
          ist es Deines Erachtens, dass Du in dieser Immobilie die nächsten
          Jahre wohnen bleibst? Wie sicher ist Deine Arbeitsstelle in der Nähe?
          Ist die Immobilie groß genug? Steht eine Vergrößerung der Familie an
          und gibt es vor Ort eine entsprechende Infrastruktur an Schulen und
          Kindergärten?
        </p>
        <p>
          Und vor allem: Kannst Du Dir diese Immobilie finanziell leisten? Wie
          viel verfügbares Einkommen steht Dir zur Verfügung? Auf wie viel
          Eigenkapital kannst Du zurückgreifen? Gibt es absehbare größere
          Ausgaben in den nächsten Jahren? Eventuell ein neues Auto, die
          Rückzahlung von Krediten o.ä.?
        </p>
        <p>
          <strong>
            Das sind eine Menge Fragen, die man sich vor einer Baufinanzierung
            stellen sollte. Wir erstellen zusammen mit Dir eine ehrliche und
            detaillierte Gegenüberstellung von Einnahmen und Ausgaben und
            ermitteln Dein frei verfügbares Kapital, um Deine Wunschimmobilie zu
            verwirklichen.
          </strong>
        </p>
        <h3>2. Der Immobiliencheck</h3>
        <p>
          Nachdem wir Deine persönliche Situation gründlich erörtert haben, muss
          die Immobilie auf Herz und Nieren geprüft werden. Wie ist der Zustand
          und besteht ein Instandhaltungsstau? Mit welcher Wertentwicklung ist
          in den nächsten Jahren und Jahrzehnten zu rechnen? Befindet sich die
          Immobilie in einer Boom-Region oder auf dem Land, von wo die Leute
          wegziehen?
        </p>
        <p>
          <strong>
            Entscheidend für den Wert einer Immobilie ist das altbekannte Mantra
            der Immobilienbranche: „Erstens Lage, zweitens Lage und drittens
            Lage!“
          </strong>
        </p>
        <p>
          Egal, ob Neubau oder eine gebrauchte Immobilie: Im Zweifel empfiehlt
          sich immer einen unabhängigen Gutachter zu Rate zu ziehen.
        </p>
        <h3>3. Maßgeschneiderte Finanzierung</h3>
        <p>
          Vor dem Abschluss des Kaufvertrages brauchst Du in der Regel die
          Zusicherung der Finanzierung (sog. Finanzierungsbestätigung) durch
          eine Bank (oder auch durch eine Versicherung). Daher gilt es, sich
          frühzeitig zu informieren, wie der Traum von der eigenen Immobilie
          finanziert werden soll und wie viel Du Dir leisten kann.
          Erfahrungsgemäß wendet sich der Käufer bei der größten Investition
          seines Lebens an seine Hausbank und wählt aus deren Angebotsportfolio
          (oftmals des angeschlossenen Baufinanzierers) eine Finanzierung aus.
          Daher kann Dir Deine Bank niemals eine auf Deine individuellen
          Bedürfnisse zugeschnittene Finanzierung und den Best-Zins am Markt
          anbieten!
        </p>
        <p>
          <strong>
            Wir gehen daher anders vor: Als erstes entwickeln wir zusammen mit
            Dir ein maßgeschneidertes Finanzierungskonzept und erst danach
            suchen wir den passenden Anbieter mit dem für Dich besten Produkt
            aus. Keiner möchte heutzutage noch mühsames vergleichen und jeder
            Bank seine Daten offenlegen, denn schließlich kann so etwas Deinen
            Scorewert negativ beeinflussen.
          </strong>
        </p>
        <p>Unsere Beratung ist dabei kostenlos und jederzeit transparent!</p>
      </ContentTextbox>
    ),
    unterlagen: (
      <ContentTextbox title="Welche Unterlagen benötigt der Kreditgeber?">
        <p>
          <strong>
            Der Kreditgeber benötigt zur Bestimmung Deiner Bonität und
            Kreditwürdigkeit Kontoauszüge zu Deinen Vermögensverhältnissen, die
            letzten drei Einkommensnachweise, den letzten Lohnsteuerbescheid und
            ggf. Angaben zu vorhandenen Verbindlichkeiten.
          </strong>{" "}
          Im Gegenzug holt die Bank eine Schufa-Auskunft ein.
        </p>
        <p>
          <strong>
            Zusätzlich benötigt der Kreditgeber von Dir die Unterlagen zur
            Immobilie, um den Immobilienwert zu ermitteln.
          </strong>
        </p>
        <img
          src={illustrationsHomeBuy}
          alt="Grafik über einen Hauskauf"
          className="w-9/12 mx-auto"
        />
      </ContentTextbox>
    ),
    foerderungen: (
      <ContentTextbox title="Welche Förderungsmöglichkeiten gibt es?">
        <h3>KfW-Darlehen</h3>
        <p>
          Die KfW-Bank bietet zinsgünstige Darlehen an, deren Zins üblicherweise
          unter dem liegt, was andere Banken anbieten können. Gefördert werden
          besonders energieschonende Immobilienprojekte, Sanierungs- und
          Modernisierungsmaßnahmen.
        </p>
        <p>
          Ein KfW-Darlehen ist allerdings nicht per se günstiger und ist oftmals
          an bestimmte Bedingungen geknüpft. Auch hier empfiehlt sich jede
          Situation mit Ihren Vor- und Nachteilen individuell zu prüfen ob es
          Dir einen persönlichen Mehrwert bietet.{" "}
        </p>
        <h3>Baukindergeld</h3>
        <p>
          Zusätzlich gibt es noch unter bestimmten Voraussetzungen das
          Baukindergeld: pro Kind bekommen Familien über einen Zeitraum von 10
          Jahren einen jährlichen Zuschuss von 1.200 EUR. Jedoch darf das
          Einkommen maximal 90.000 € betragen (zusätzlich 15.000 € für jedes
          weitere Kind). Eine Auflistung findest Du hier:
        </p>
        <div className="overflow-auto">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="border px-4 py-2">Anzahl der Kinder</th>
                <th className="border px-4 py-2">
                  max. durchschnittliches Haushaltseinkommen in Euro
                </th>
                <th className="border px-4 py-2">
                  Baukindergeld jährlich in Euro
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">1</td>
                <td className="border px-4 py-2">90.000</td>
                <td className="border px-4 py-2">1.200</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">2</td>
                <td className="border px-4 py-2">105.000</td>
                <td className="border px-4 py-2">2.400</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">3</td>
                <td className="border px-4 py-2">120.000</td>
                <td className="border px-4 py-2">3.600</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">4</td>
                <td className="border px-4 py-2">135.000</td>
                <td className="border px-4 py-2">4.800</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Weitere Informationen gibt es auf der Webseite der KfW:{" "}
          <a
            href="https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Baukindergeld-(424)/"
            target="_blank"
            rel="noopener noreferrer"
            className="break-all"
          >
            https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Baukindergeld-(424)/
          </a>
        </p>
        <h3>(Wohn-) Riester</h3>
        <p>
          Der Abschluss eines (Wohn-)Riester-Vertrages ist insbesondere für
          Gutverdiener und Familien mit Kindern lohnend. Banken und
          Versicherungen bieten Dir die Möglichkeit die staatliche Förderung zu
          nutzen. Dies macht im Gesamtkonzept Sinn, da Du parallel zur
          Finanzierung Kapital ansparen kannst und somit schneller die
          Finanzierung zu beenden. Da das Eigenheim auch eine Form der
          Altersvorsorge darstellt, fördert dies der Staat und schenkt Dir Geld
          in Form von Zuschüssen. Ein Haushalt erhält 175,- € für jeden
          Erwachsenen und 300,- € für jedes Kind pro Jahr. Die Höhe des
          monatlichen Zahlbeitrages richtet sich nach Deinem Einkommen,
          allerdings besteht die Möglichkeit Riester-Zahlbeiträge jedes Jahr als
          Sonderausgaben steuerlich geltend zu machen!
        </p>
        <p>
          <strong>ACHTUNG:</strong> Riester ist nicht gleich Riester, auch hier
          gilt es zu vergleichen. Da der Zahlbetrag zu 100 % garantiert ist,
          empfiehlt es sich einen Sparkern zu wählen, der sowohl Dein Geld, als
          auch die stattlichen Förderungen verzinslich für Dich arbeitet. Diesen
          Mehrwert bieten Dir heutzutage in der Regel investmentbasierte
          Produkte, da Bausparverträge nicht mehr zeitgemäß sind.
        </p>
        <h3>Weitere Förder-Möglichkeiten</h3>
        <p>
          Es gibt unterschiedliche Förderungen, die wir nicht alle abbilden
          können, da sich die Situation regelmäßig ändert. Unsere Experten haben
          jedoch jederzeit den Überblick über alle Möglichkeiten.{" "}
          <strong>
            Da Förderungen auch stark an bestimmte Bedingungen geknüpft sind,
            kannst Du Dich auf unsere Experten verlassen, die beim Finanz-Check
            Deine persönliche Situation erörtern und so alle Fördermöglichkeiten
            offenlegen.
          </strong>
        </p>
      </ContentTextbox>
    ),
    darlehen: (
      <ContentTextbox title="Annuitätendarlehen als üblichste Form der Immobilienfinanzierung">
        <p>
          Die am meisten verbreitete Form des Immobiliendarlehens ist das
          „Annuitätendarlehen“ mit einer Zinsbindungsfrist von 10 oder 15 Jahren
          Laufzeit. Aus der anfänglichen Tilgungsrate plus dem
          Hypothekenzinssatz errechnet sich die fixe monatliche Rate, der so
          genannte Schuldendienst. Daher wird schon mit der ersten Ratenzahlung
          das Darlehen getilgt. Mit sinkender Darlehenssumme reduziert sich dann
          im Zeitablauf die Zinslast, so dass der Tilgungsanteil der Rate
          permanent steigt.
        </p>
        <p>
          Üblicherweise wird eine Sondertilgungsmöglichkeit von 5 % eingeräumt,
          eine höhere Sondertilgungsmöglichkeit von bspw. 10 % wird mit einem
          Aufschlag auf den Zinssatz erkauft.
        </p>
        <p>
          Wichtig ist, die anfängliche Tilgungsrate nicht zu niedrig zu wählen,
          insbesondere bei dem heutigen niedrigen Zinsniveau, damit mit der
          Darlehenssumme auch die Zinslast schnell sinkt und eine
          Anschlussfinanzierung nicht zum unnötigen Risiko wird.
        </p>
        <p>
          Beim „Endfälligen Darlehen“ wird die Tilgung erst zum Ende der
          Laufzeit fällig. Diese Finanzierung ist eher unüblich. In Verbindung
          mit der Abtretung von Ansprüchen aus Kapitallebensversicherungen oder
          in Kombination mit Bausparverträgen tauchen diese aber gelegentlich
          auf. Bei der Bausparvariante zahlt der Kreditnehmer in einen
          Bausparvertrag ein, dessen Auszahlung bei Fälligkeit dann in die
          Tilgung des Darlehens fließt. Der Kunde wird mit der vermeintlichen
          Sicherheit des Bausparvertrages und der (relativ) guten Verzinsung des
          Bausparguthabens in diese Kombilösung gelockt.{" "}
          <strong>
            Vergleichsrechnungen zeigen, dass der Abschluss eines
            Annuitätendarlehens, bei dem die Tilgung sofort beginnt, generell
            die günstigere Alternative für den Kunden ist, da bis zur Fälligkeit
            des Bausparguthabens die Zinsen auf die gesamte Darlehenssumme
            gezahlt werden.
          </strong>{" "}
          Aus diesem Grund kann von dieser Variante der Immobilienfinanzierung
          nur abgeraten werden. Bausparen ist generell nicht mehr zeitgemäß: die
          Verträge sind teuer, unflexibel und die Anlage des Geldes ist aufgrund
          des niedrigen Zinses in anderen Produkten attraktiver.
        </p>
      </ContentTextbox>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Niedrige Zinsen bei der Baufinanzierung"
        description="Als vertrauensvoller Ansprechpartner, vergleichen wir für Dich bei nahezu allen deutschen Banken, um für Dich den besten Kredit zu finden."
        path="/finanzierungen/baufinanzierung/"
      />
      <Header
        link={anfrageLink}
        linktext="Jetzt die passende Baufinanzierung finden"
        image={data.image.childImageSharp.fluid}
      >
        <div className="text-sm font-semibold tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Finanzierungen
        </div>
        <h1 className="mt-1 text-2xl leading-relaxed sm:text-3xl lg:text-4xl font-extrabold text-gray-900  ">
          <span className="text-blue-500">
            Niedrige Zinsen bei der Baufinanzierung:
          </span>{" "}
          Wir vergleichen und beraten Dich unabhängig
        </h1>

        <div className="my-8 inline-flex ">
          <Link to={anfrageLink}>
            <SvgFeature svg={svg.finance} title="Spare Geld und Zeit" />
          </Link>
        </div>

        <p className="text-lg leading-7 text-gray-700">
          Du träumst von Deinen eigenen vier Wänden und benötigst eine passende
          Immobilienfinanzierung? Wir möchten Dir gerne dabei helfen! Als
          vertrauensvoller Ansprechpartner vergleichen wir für Dich bei nahezu
          allen deutschen Banken, um für Dich den besten Kredit zu finden. Damit
          auch alles reibungslos funktioniert, organisieren wir gemeinsam mit
          Dir alle wichtigen Dokumente. Zusätzlich beraten wir Dich auch
          hinsichtlich staatlicher Förderungen wie KfW-Kredite, Wohn-Riester und
          vielen mehr.
        </p>
      </Header>

      <FeatureSection title="Was uns besonders macht">
        <SvgFeature
          svg={svg.check}
          title="Best-Zins ab 0,5 % (Zinssätze variieren)"
          text="Wir vergleichen und finden eine günstige Immobilienfinanzierung."
        />
        <SvgFeature
          svg={svg.check}
          title="Unabhängiger Vergleich von über 500 Banken"
          text="Wir vergleichen bei fast allen deutschen Banken."
        />
        <SvgFeature
          svg={svg.check}
          title="Bis zu 40 Jahre Zinsbindung"
          text="Finanzierst Du die Immobilie komplett, können wir Dir bis zu 40 Jahre Zinsbindung anbieten"
        />
        <SvgFeature
          svg={svg.check}
          title="Die richtigen Dokumente bereitstellen"
          text="Wir helfen bei der Bereitstellung der Dokumente für die Bank."
        />
        <SvgFeature
          svg={svg.check}
          title="Anschlussfinanzierung"
          text="Denke auch an Morgen und sichere dir jetzt schon die Niedrig-Zinsen für später."
        />
        <SvgFeature
          svg={svg.check}
          title="Kostenfreie Beratung"
          text="Wir beraten Dich natürlich kostenlos. Du zahlst nur die Raten zu Deinem Kredit."
        />
      </FeatureSection>

      <ContContentTwoColumns col1={content.warum} col2={content.wann} />

      <CtaSection
        title1="Spare Geld und Zeit"
        text="Niedrige Zinsen bei der Immobilienfinanzierung: Wir vergleichen und beraten Dich unabhängig."
        link1={anfrageLink}
        link1text="Jetzt die passende Immobilienfinanzierung finden"
      />
      <ContContentTwoColumns col1={content.was} col2={content.unterlagen} />
      <ContContentTwoColumns
        col1={content.foerderungen}
        col2={content.darlehen}
      />
    </Layout>
  )
}
